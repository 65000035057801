body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: black;
  color: white;
  font-family: 'VT323', monospace;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
}

.App {
  text-align: center;
  width: 100%;
}

.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.header {
  margin-bottom: 24px;
}

.social-links {
  display: flex;
  justify-content: center;
}

.social-links a {
  margin: 0 8px;
}

.icon {
  color: white;
  font-size: 35px;
  margin: 0 8px;
}

.social-icons {
  display: flex;
  justify-content: center;
}

.icon:hover {
  color: rgb(85, 186, 54)
}

.header img {
  max-width: 100%; /* Ensure the image doesn't exceed its container */
  height: auto; /* Maintain aspect ratio */
}

/* Center the header content */
.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
}

/* Make social icons responsive */
.social-icons {
  display: flex;
  justify-content: center;
  align-items: center; /* Vertically center the icons */
  flex-wrap: wrap; /* Allow icons to wrap to the next line on small screens */
  margin-top: 10px; /* Add some space at the top */
}

.social-icons a {
  margin: 5px; /* Add spacing between icons */
}
.icon {
  font-size: 50px;
  padding: 0px 5px 10px 5px;
}

/* Adjust icon size for smaller screens */
@media screen and (max-width: 600px) {
  .icon {
    font-size: 45px;
    padding: 0px 25px 20px 25px;
  }
}

/* Adjust title font size for smaller screens */
@media screen and (max-width: 600px) {
  .title {
    font-size: 30px;
  }
}